<template>
  <div class="pay">
    <div class="title"></div>
    <vue-qr
      :correctLevel="3"
      :autoColor="false"
      :download="downloadFilename"
      :text="url"
      :logoSrc="imageUrl"
      :size="200"
      :margin="0"
      :logoMargin="3"></vue-qr>
      <p class="paymoney">支付金额：{{ $route.query.amount }}元</p>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  data(){
    return{
      url: `${this.$baseH5Url}/juruipay/index?productName=${this.$route.query.productName}&servicePeriod=${this.$route.query.servicePeriod}&amount=${this.$route.query.amount}&customerName=${this.$route.query.customerName}&contactPhone=${this.$route.query.contactPhone}&userId=${this.$route.query.userId}&productId=${this.$route.query.productId}&time=${new Date().getTime()}`,
      imageUrl: require(`@/assets/logo.png`),
      downloadFilename: ''
    }
  }
}
</script>
<style scoped>
.pay{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.paymoney{
  font-size: 22px;
}
.title{
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 80px;
}
</style>