<template>
  <van-form @submit="onSubmit" class="form">
    <!-- <div class="title">设置服务信息</div> -->
    <van-field readonly v-model="productName" name="product" label="服务类型" input-align="right"/>
    <van-field v-if="columns.length>0" readonly clickable name="servicePeriod" input-align="right" :value="value" label="服务年限" placeholder="请选择服务年限" @click="showPicker = true" :rules="[{ required: true, message: '请选择服务年限' }]"/>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" value-key="label" />
    </van-popup>
    <van-field placeholder="请填写服务价格" v-model="price" input-align="right" name="amount" type="digit" label="服务价格" :rules="[{ required: true, message: '请填写服务价格' }]"/>
    <van-field placeholder="请填写客户姓名" v-model="userName" input-align="right" name="customerName" label="客户姓名" :rules="[{ required: true, message: '请填写客户姓名' }]"/>
    <van-field placeholder="请填写手机号" v-model="phoneNumber" input-align="right" name="contactPhone" type="tel" label="手机号码" :rules="[{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号格式错误' }]"/>
    <div>
      <van-button round block type="info" native-type="submit">生成收款二维码</van-button>
    </div>
  </van-form>
</template>
<script>
  export default {
    data() {
      return {
        productName: this.$route.query.productName,
        productId: this.$route.query.productId,
        userName: '',
        phoneNumber: '',
        price: '',
        showPicker: false,
        value: '',
        columns: sessionStorage.getItem('changeService')!=='null'?JSON.parse(sessionStorage.getItem('changeService')):[]
      }
    },
    methods: {
      onSubmit(values) {
        values.userId = this.$route.query.id
        values.productName = this.$route.query.productName
        values.productId = this.$route.query.productId
        if(values.servicePeriod) values.servicePeriod = this.servicePeriod
        this.$router.push({path:'/Catalogue/payQr',query: values })
      },
      onConfirm(e){
        this.showPicker = false
        this.value = e.label
        this.servicePeriod = e.value
      }
    }
  }
</script>
<style scoped>
.form{
  padding: 20px 10px;
}
.title{
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 30px;
}
</style>